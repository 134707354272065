.l-main {
   width: 100%;
   min-height: 100px;
}

.l-container {
   display: flex;
}

.l-left {
   flex: 1;
   height: 100%;
   position: relative;
}

.l-middle {
   flex: 1;
   padding: 1rem;
}

.l-right {
   flex: 1;
   padding: 1rem;
}

.l-bg-image {
   /* background: url("D://AadityaPadte//Internship//Medisage//edumentin-registraion-frontend//src//TempAssets//Group-24.png"); */
   height: 760px;
}

.l-data {
   position: absolute;
   z-index: 1;
   top: 0;
   left: 0;
   padding: 1rem;
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 70%;
}

.l-topic {
   color: #FFA713;
   font-size: 2rem;
   width: 100%;
   text-align: center;
   margin: 10px 0 0 0;
   letter-spacing: 15px;
}

.l-headline {
   color: #FFF;
   font-size: 2.2rem;
   width: 100%;
   text-align: center;
   margin: 10px 0;
}

.l-light-image {
   width: 96%;
   /* padding-top: 2rem; */
}

.l-line {
   width: 100%;
   height: 5px;
   background-color: #FFA713;
   border: none;
}

.l-title-img {
   width: 70%;
}

.l-title {
   margin-top: 80px;
}

.l-info {
   margin-top: 2rem;
}

.l-main-info {
   padding: 10px;
   display: flex;
   margin-bottom: 10px;
}

.l-logos {
   width: 25px;
}

.l-dataInfo {
   padding-left: 20px;
   font-size: 18px;
   font-weight: 500;
}

.l-profession {
   display: flex;
   align-items: end;
   font-size: 12px;
   font-weight: 500;
}

.l-dates-info {
   color: #655249;
   display: flex;
}

.l-dateTime {
   padding: 20px 20px 20px 10px;
   font-size: 20px;
   font-weight: 600;
}

.l-desc {
   padding: 20px 10px 10px 10px;
   font-size: 10px;
}

.l-gsk-logo {
   width: 100%;
   text-align: end;
}

.l-form-div {
   width: 100%;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
   min-height: 680px;
   border-radius: 20px;
}

.l-form-login {
   padding: 20px;
   color: #1E5B20;
}

.l-login-email {
   display: flex;
   padding: 5px 5px 0 0;
}
.l-input{
   border: none;
   border-bottom: 1px solid #408243;
   width: 100%;
   margin-left: 10px;
   color: gray;
   font-size: 16px;
   padding: 5px;
}
.l-select{
   border: none;
   background-color: #eee;
   padding: 10px;
   border-radius: 20px;
   margin-left: 10px;
   color: #408243;
   width: 60%;     
}
.l-select:focus{
   outline: none;
}
.l-input:focus{
   outline: none;
}
.l-login-button{
   background-color: #428243;
   border: none;
   display: flex;
   align-items: center;
   height: 31px;
   border-radius: 30px;
   width: 100px;
   color: #fff;
   font-size: 12px;
   box-shadow: #428243 0px 5px 15px;
   cursor: pointer;
   margin-left: 10px;
}
.l-required{
   width: 97%;
   font-size: 10px;
   text-align: end;
   margin-top: 5px;
}
.l-required-spec{
   width: 97%;
   text-align: center;
   font-size: 10px;
   margin-top: 5px;
}
.l-add-calender{
   padding: 0 20px;
   color: #428243;
   
}
.l-calenders{
   display: flex;
   align-items: center;
}
@media screen and (max-width:1200px) and (min-width:880){
   .l-headline{
      font-size: 1.7rem;
   }
   .l-bg-image {
      min-height: 100px;
   }
   .l-icons{
      width: 10%;
   }
}
@media screen and (max-width:880px){
   .l-container{
      flex-direction: column;
   }
   .l-data{
      width: 95%;
   }
   .l-bg-image {
      height: 900px;
   }
   /* l-icons{ */
      /* width: 8%;
   } */
}
@media screen and (max-width:680px) {
   .l-container{
      flex-direction: column;
   }
   .l-data{
      width: 90%;
   }
   .l-bg-image {
      min-height: 100px;
   }
   /* .l-form-div{
      height: 700px;
   } */
   .l-login-email{
      height: 40px;
   }
   .l-icons{
      width: 20%;
   }
   .l-form-login{
      padding: 10px;
   }
   .l-login-button{
      margin-top: 10px;
   }
}
a{
   text-decoration: none;
}